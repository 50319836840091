@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&family=Poppins:wght@400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  #root {
    @apply min-h-screen flex flex-col;
  }
  :root {
    --primary: 56, 84, 166;
    --secondary: 234, 234, 234;
    --accent-orange: 242, 158, 56;
    --accent-grey: 207, 204, 199;
  }

  html,
  body {
    @apply font-poppins m-0 leading-normal;
    font-size: 16px;
  }

  footer {
    @apply z-50;
  }

  @screen xl {
    html,
    body {
      font-size: 18px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply leading-tight;
  }

  input:invalid ~ span {
    display: block;
  }
}

@layer components {
  .card-shadow {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  }

  .row-shadow {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  }

  .head-shadow {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }

  .table-space {
    border-spacing: 0 2px;
  }

  @media only screen and (min-width: 768px) {
    .table-space {
      border-spacing: 0 10px;
    }
  }
}
